/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import tippy from 'tippy.js'
import { Fancybox } from '@fancyapps/ui'
import Inputmask from 'inputmask'
import MenuToggle from '@/assets/js/Menu/MenuToggle/MenuToggle'
import ButtonCatalog from '@/assets/js/Button/ButtonCatalog/ButtonCatalog'
import ButtonTop from '@/assets/js/Button/ButtonTop/ButtonTop'
import CartButton from '@/assets/js/Cart/CartButton/CartButton'
import ProductCount from '@/assets/js/ProductCount/ProductCount'
import ProductButtons from '@/assets/js/ProductButtons'
import FilterButton from '@/assets/js/FilterButton/FilterButton'
import Cookie from '@/assets/js/Cookie/Cookie'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import Faq from '@/assets/js/Faq/Faq'
import ShowHidden from '@/assets/js/ShowHidden/ShowHidden'
import ButtonSearch from '@/assets/js/Button/Search/ButtonSearch'
import Tag from '@/assets/js/Tag/Tag'
import HeaderMobile from '@/assets/js/Header/Mobile/HeaderMobile'
import MenuMobile from '@/assets/js/Menu/Mobile/MenuMobile'
import AppFormPopup from '@/components/Form/Popup/AppFormPopup.vue'

const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    tippy('.tippy--icon', {
        theme: 'material',
        arrow: true,
        placement: 'top',
        animation: 'scale-extreme',
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true
    })

    Fancybox.bind('[data-subscribe]')
    Inputmask('+7 (999) 999-99-99').mask('.input--phone')
    Inputmask('99.99.9999').mask('.input--date')
    Inputmask('99:99').mask('.input--time')

    if(document.getElementById('block--form-popup') != null) {
        createApp({})
            .component('block-form-popup', AppFormPopup)
            .use(Maska)
            .use(form)
            .mount('#block--form-popup')
    }

    new MenuToggle()
    new ButtonCatalog()
    new ButtonTop()
    new CartButton()
    new ProductCount()
    new ProductButtons()
    new FilterButton()
    new Cookie()
    new SwiperSlider()
    new Faq()
    new ShowHidden()
    new ButtonSearch()
    new Tag()
    new HeaderMobile()
    new MenuMobile()
})
