import Swiper, {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs, Lazy} from 'swiper'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs, Lazy])

export default class SwiperSlider {
    createSlider () {
        return new Swiper('#slider--slider', {
            slidesPerView: 1,
            spaceBetween: 50,
            navigation: {
                nextEl: '.slider--slider-next',
                prevEl: '.slider--slider-prev',
            },
            pagination: {
                el: '.swiper-pagination--slider',
                clickable: true
            },
            breakpoints: {}
        })
    }
    createProductsPopular () {
        return new Swiper('#slider--products-popular', {
            slidesPerView: 5.5,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--products-popular-next',
                prevEl: '.slider--products-popular-prev',
            },
            pagination: {
                el: '.swiper-pagination--products-popular',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.25,
                    spaceBetween: 5
                },
                320: {
                    slidesPerView: 1.5,
                    spaceBetween: 5
                },
                360: {
                    slidesPerView: 1.5,
                    spaceBetween: 5
                },
                375: {
                    slidesPerView: 1.5,
                    spaceBetween: 5
                },
                414: {
                    slidesPerView: 1.75,
                    spaceBetween: 5
                },
                480: {
                    slidesPerView: 2.125,
                    spaceBetween: 5
                },
                514: {
                    slidesPerView: 2.25,
                    spaceBetween:  5
                },
                640: {
                    slidesPerView: 2.75,
                    spaceBetween: 10
                },
                768: {
                    slidesPerView: 3.25,
                    spaceBetween: 10
                },
                991: {
                    slidesPerView: 3.5,
                    spaceBetween: 10
                },
                1024: {
                    slidesPerView: 4.5,
                    spaceBetween: 15
                },
                1240: {
                    slidesPerView: 5.25,
                    spaceBetween: 15
                },
                1440: {
                    slidesPerView: 5.5,
                    spaceBetween: 15
                }
            }
        })
    }
    createProductsView () {
        return new Swiper('#slider--products-view', {
            slidesPerView: 5.5,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--products-view-next',
                prevEl: '.slider--products-view-prev',
            },
            pagination: {
                el: '.swiper-pagination--products-view',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.25,
                    spaceBetween: 5
                },
                320: {
                    slidesPerView: 1.5,
                    spaceBetween: 5
                },
                360: {
                    slidesPerView: 1.5,
                    spaceBetween: 5
                },
                375: {
                    slidesPerView: 1.5,
                    spaceBetween: 5
                },
                414: {
                    slidesPerView: 1.75,
                    spaceBetween: 5
                },
                480: {
                    slidesPerView: 2.125,
                    spaceBetween: 5
                },
                514: {
                    slidesPerView: 2.25,
                    spaceBetween:  5
                },
                640: {
                    slidesPerView: 2.75,
                    spaceBetween: 10
                },
                768: {
                    slidesPerView: 3.25,
                    spaceBetween: 10
                },
                991: {
                    slidesPerView: 3.5,
                    spaceBetween: 10
                },
                1024: {
                    slidesPerView: 4.5,
                    spaceBetween: 15
                },
                1240: {
                    slidesPerView: 5.25,
                    spaceBetween: 15
                },
                1440: {
                    slidesPerView: 5.5,
                    spaceBetween: 15
                }
            }
        })
    }
    createProductGallery () {
        return new Swiper('#slider--product-gallery', {
            slidesPerView: 1,
            spaceBetween: 50,
            navigation: {
                nextEl: '.slider--product-gallery-next',
                prevEl: '.slider--product-gallery-prev',
            },
            pagination: {
                el: '.swiper-pagination--product-gallery',
                clickable: true
            },
            breakpoints: {}
        })
    }
    createReview () {
        return new Swiper('#slider--review', {
            slidesPerView: 3.5,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--review-next',
                prevEl: '.slider--review-prev',
            },
            pagination: {
                el: '.swiper-pagination--review',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.25,
                    spaceBetween: 8
                },
                320: {
                    slidesPerView: 1.25,
                    spaceBetween: 8
                },
                360: {
                    slidesPerView: 1.25,
                    spaceBetween: 8
                },
                375: {
                    slidesPerView: 1.25,
                    spaceBetween: 8
                },
                414: {
                    slidesPerView: 1.5,
                    spaceBetween: 8
                },
                480: {
                    slidesPerView: 1.5,
                    spaceBetween: 8
                },
                514: {
                    slidesPerView: 1.5,
                    spaceBetween: 8
                },
                640: {
                    slidesPerView: 1.5,
                    spaceBetween: 10
                },
                768: {
                    slidesPerView: 2.5,
                    spaceBetween: 10
                },
                991: {
                    slidesPerView: 2.5,
                    spaceBetween: 10
                },
                1024: {
                    slidesPerView: 2.5,
                    spaceBetween: 15
                },
                1240: {
                    slidesPerView: 3.5,
                    spaceBetween: 15
                }
            }
        })
    }
    createSection () {
        return new Swiper('#slider--section', {
            slidesPerView: 6,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--section-next',
                prevEl: '.slider--section-prev',
            },
            pagination: {
                el: '.swiper-pagination--section',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2.25,
                    spaceBetween: 8
                },
                320: {
                    slidesPerView: 2.25,
                    spaceBetween: 8
                },
                360: {
                    slidesPerView: 2.25,
                    spaceBetween: 8
                },
                375: {
                    slidesPerView: 2.25,
                    spaceBetween: 8
                },
                414: {
                    slidesPerView: 3.25,
                    spaceBetween: 8
                },
                480: {
                    slidesPerView: 3.25,
                    spaceBetween: 10
                },
                514: {
                    slidesPerView: 3.25,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 4.25,
                    spaceBetween: 10
                },
                768: {
                    slidesPerView: 4.25,
                    spaceBetween: 10
                },
                991: {
                    slidesPerView: 5.25,
                    spaceBetween: 10
                },
                1024: {
                    slidesPerView: 5.25,
                    spaceBetween: 15
                },
                1240: {
                    slidesPerView: 6,
                    spaceBetween: 15
                }
            }
        })
    }
    createAdvantage () {
        return new Swiper('#slider--advantage', {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--advantage-next',
                prevEl: '.slider--advantage-prev',
            },
            pagination: {
                el: '.swiper-pagination--advantage',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.25,
                    spaceBetween: 10
                },
                320: {
                    slidesPerView: 1.25,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1.25,
                    spaceBetween: 10
                },
                375: {
                    slidesPerView: 1.25,
                    spaceBetween: 10
                },
                414: {
                    slidesPerView: 1.5,
                    spaceBetween: 10
                },
                480: {
                    slidesPerView: 1.5,
                    spaceBetween: 15
                },
                514: {
                    slidesPerView: 1.5,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 1.5,
                    spaceBetween: 15
                },
                768: {
                    slidesPerView: 2.25,
                    spaceBetween: 15
                },
                991: {
                    slidesPerView: 2.25,
                    spaceBetween: 20
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20
                }
            }
        })
    }

    constructor () {
        if(document.getElementById('slider--slider')!= null) {
            this.createSlider()
        }

        if(document.getElementById('slider--products-popular')!= null) {
            this.createProductsPopular()
        }

        if(document.getElementById('slider--products-view')!= null) {
            this.createProductsView()
        }

        if(document.getElementById('slider--product-gallery')!= null) {
            this.createProductGallery()
        }

        if(document.getElementById('slider--review')!= null) {
            this.createReview()
        }

        if(document.getElementById('slider--section')!= null) {
            this.createSection()
        }

        if(document.getElementById('slider--advantage')!= null) {
            this.createAdvantage()
        }
    }
}
