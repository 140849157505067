/*global $*/
/*eslint no-undef: "error"*/
export default class Tag {
    classItem = 'tag--item'
    classButton = 'tag--button'
    classText = 'tag--text'
    classItemOpen = 'tag---open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let textTo = $(this).data('to')
            let textDo = $(this).data('do')

            $(this).closest(`.${self.classItem}`).toggleClass(self.classItemOpen)

            if ($(this).closest(`.${self.classItem}`).hasClass(self.classItemOpen)) {
                $(this).closest(`.${self.classItem}`).find(`.${self.classText}`).text(textDo)
            } else {
                $(this).closest(`.${self.classItem}`).find(`.${self.classText}`).text(textTo)
            }
        })
    }
}
